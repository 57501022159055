import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
import "bootstrap/dist/css/bootstrap.min.css"
import postStyles from "../styles/post.module.css"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const origin = this.props.location.origin

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.image.publicURL}
        />

        <Row>
          <Col lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
            <div>
              <img
                className={postStyles.image}
                src={post.frontmatter.image.publicURL}
              />
              <h1>{post.frontmatter.title}</h1>
              <p className={postStyles.date}>{post.frontmatter.date}</p>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <hr />
            </div>
            <div className={postStyles.allBtn}>
              <FacebookShareButton
                url={origin + post.fields.slug}
                className={postStyles.fbBtn}
              >
                <FacebookIcon size={50} />
              </FacebookShareButton>
              <TwitterShareButton url={origin + post.fields.slug}>
                <TwitterIcon size={50} />
              </TwitterShareButton>
            </div>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`

BlogPostTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  markdownRemark: PropTypes.object,
  title: PropTypes.string,
  origin: PropTypes.string,
}
